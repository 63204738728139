import React from "react";
import { Wrapper } from "./styles";
import { Container } from "../../common/Container";
import { Details, SkillsWrapper, Thumbnail } from "./styles";
import dev from "assets/illustrations/celebrate.svg";

export const About = () => (
  <Wrapper id="about">
    <SkillsWrapper as={Container}>
      <Thumbnail>
        <img src={dev} alt="Graphic of people clicking glasses." />
      </Thumbnail>
      <Details>
        <h1>About Us</h1>
        <p>
          DropHop started as a solution to help our favorite neighborhood bar
          increase their sales. We knew online ordering offered a great
          advantage for bars and breweries, but the only systems available were
          third parties that took huge cuts.
        </p>
        <p>
          We developed a web app that integrates with your website and is cost
          efficient, so you can keep your branding and your profits.
        </p>
        <p>
          DropHop provides a sleek interface for your customers to see your
          menu, order, and pay online without ever leaving your site. It’s easy
          to set up and easy to update so you can spend your valuable time on
          doing what you love.
        </p>
        <p>
          We value our local beer community, and we want y’all to stay open!
        </p>
      </Details>
    </SkillsWrapper>
  </Wrapper>
);
