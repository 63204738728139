import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { Container, Button } from "components/common";
import { Wrapper, SkillsWrapper, Details, Thumbnail } from "./styles";
import { graphql, useStaticQuery } from "gatsby";

export const Video = () => {
  const data = useStaticQuery(graphql`
    query CloudinaryImage {
      cloudinaryMedia(asset_id: { eq: "30fce4e0c0adfbd7d84191f3d25c6cb9" }) {
        secure_url
      }
    }
  `);
  return (
    <Wrapper id="stats">
      <SkillsWrapper as={Container}>
        <Thumbnail>
          <video src={data.cloudinaryMedia.secure_url} width="470" controls />
        </Thumbnail>
        <Details>
          <h1>What is DropHop?</h1>
          <p>
            We set you up a one-page website integrated with your{" "}
            <strong>Untappd</strong> menu. All beers, cocktails, food, prices
            and container options are pulled automatically from your Untappd
            menu and made immediately available for purchase on your website.
            Any time your menu is updated, your website will immediately update
            to reflect those changes.
          </p>
          <Button as={AnchorLink} href="#whatis">
            Get Demo
          </Button>
        </Details>
      </SkillsWrapper>
    </Wrapper>
  );
};
