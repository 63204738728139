import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { Container, Button } from "components/common";
import dev from "assets/illustrations/skills.svg";
import {
  Wrapper,
  SkillsWrapper,
  SkillsBackground,
  Details,
  Thumbnail
} from "./styles";

export const Skills = () => (
  <Wrapper id="stats">
    <SkillsBackground> </SkillsBackground>
    <SkillsWrapper as={Container}>
      <Details>
        <h1>The Future of the Service Industry is Here</h1>
        <ul>
          <li>
            Online ordering is shown to increase the average check size by 25%
          </li>
          <li>
            60% of US customers order takeout or delivery at least once/week,
            and that's only increasing.
          </li>
          <li>
            Online ordering has grown 300% faster than dine-in services since
            2014.
          </li>
        </ul>
        <Button as={AnchorLink} href="#contact">
          Get Demo
        </Button>
      </Details>
      <Thumbnail>
        <img src={dev} alt="Graphic of a man viewing statistics on a tablet." />
      </Thumbnail>
    </SkillsWrapper>
  </Wrapper>
);
