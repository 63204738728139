import React from "react";

import { useStaticQuery, graphql } from "gatsby";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { Header } from "components/theme";
import { Container, Button } from "components/common";
import dev from "assets/illustrations/dev.svg";
import { Wrapper, IntroWrapper, Details, Thumbnail, Logo } from "./styles";
import Img from "gatsby-image";

export const Intro = () => {
  const data = useStaticQuery(graphql`
    query LogoQuery {
      file(relativePath: { eq: "logo-512x512.png" }) {
        childImageSharp {
          fixed(height: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);
  return (
    <Wrapper>
      <Header />
      <IntroWrapper as={Container}>
        <Details>
          <Logo>
            <h1>DropHop</h1>
            <Img fixed={data.file.childImageSharp.fixed} />
          </Logo>
          <h4>Online Ordering for Bars and Breweries</h4>
          <h3>Starting at $9/month and 2% of monthly online sales</h3>
          <Button as={AnchorLink} href="#contact">
            Get Demo
          </Button>
        </Details>
        <Thumbnail>
          <img src={dev} alt="online shopping cart illustration" />
        </Thumbnail>
      </IntroWrapper>
    </Wrapper>
  );
};
