import React from "react";
import { Button, Input, Textarea } from "components/common";

export default () => (
  <div>
    <h1>Let's Sell This Beer</h1>
    <form
      name="contact"
      method="POST"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
    >
      <input type="hidden" name="form-name" value="contact" />
      <p hidden>
        <label>
          Don’t fill this out: <input name="bot-field" />
        </label>
      </p>
      <div>
        <label>
          Your Name: <Input type="text" name="name" />
        </label>
      </div>
      <div>
        <label>
          Email: <Input type="email" name="email" />
        </label>
      </div>
      <div>
        <label>
          Phone Number: <Input type="phone" name="phone" />
        </label>
      </div>
      <div>
        <label>
          Message:{" "}
          <Textarea
            name="message"
            placeholder="When would be a good time for a live demo?"
          />
        </label>
      </div>

      <div>
        <Button type="submit">Send</Button>
      </div>
    </form>
  </div>
);
